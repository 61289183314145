/* Built In Imports */
import mixpanel from 'mixpanel-browser';

/* Components */
import config from '@config';



export const mixPanel = (path, buildType) => {
  // console.log('mixPanel is', path, buildType);
  // Initialize Mixpanel with your project key
  const MIXPANEL_TOKEN = config.MIXPANEL_TOKEN;
  const mixPanelEnable = process.env.NODE_ENV !== 'production';

  const isIeoPage = path?.includes('inner-engineering');
  // console.log('isIeoPage is', isIeoPage, isIeoPage, buildType);

  if (mixPanelEnable && MIXPANEL_TOKEN && (buildType || isIeoPage)) {
    // console.log('mixpanel init starts', MIXPANEL_TOKEN, isIeoPage);

    mixpanel.init(MIXPANEL_TOKEN || '', {
      debug: config.MIXPANEL_DEBUG,
      track_pageview: false,
      ignore_dnt: true,
      persistence: 'localStorage',
    });

    const action = {
      identify: id => {
        mixpanel.identify(id);
      },
      alias: id => {
        mixpanel.alias(id);
      },
      track: (name, props) => {
        mixpanel?.track(name, props);
      },
      people: () => {
        set: props => {
          mixpanel.people.set(props);
        };
      },
    };

    return action;
  }
  return {};
};
