/* Built In Imports */
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

/* Internal Imports */
/* Components */
// import { OptinMonsterEmbed } from '@ieo/Components/OptinMonsterEmbed';
import Layout from '@components/Layout/Layout';
import BuildTimeComment from '@components/Utility/BuildTimeComment';
import LbLayout from '@lb/components/Layout/Layout';
import MsrLayout from '@msr/components/Layout/Layout';
import AuthContextProvider from '@store/auth-context';
import { BUILD_TYPE_CONFIG } from '@utils/index';
import { mixPanel } from '@utils/Mixpanel';
import { ishaTheme } from '../styles/theme/index';

/* Services */
/* Styles */

let nextBuildType = process?.env?.NEXT_PUBLIC_BUILDTYPE?.toUpperCase();
const { buildType } = BUILD_TYPE_CONFIG;

if (nextBuildType === buildType.LINGABHAIRAVI) {
  await import('../styles/globals-lb.css');
} else if (nextBuildType === buildType.MSR) {
  await import('../styles/globals-msr.css');
} else {
  await import('../styles/globals.css');
}

/**
 * @param Component
 * @param pageProps
 */

// export default ErrorBoundary
const ISOApp = ({ Component, pageProps }) => {
  const [blType, setBlType] = useState(nextBuildType);
  const firstUpdate = useRef(true);
  const router = useRouter();

  const tagManagerArgs = {
    gtmId: 'GTM-5G2NJ8P',
  };

  useEffect(() => {
    if (
      window?.location.pathname.indexOf(
        '/yoga-meditation/additional-programs/bhairavi-sadhana'
      ) > -1
    ) {
      nextBuildType = buildType.LINGABHAIRAVI;
      setBlType(buildType.LINGABHAIRAVI);
    }

    setTimeout(function () {
      if (firstUpdate.current) {
        if (process.browser) {
          TagManager.initialize(tagManagerArgs);
        }
        firstUpdate.current = false;
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          url: window.location.href,
          title: window.document.title,
        });
      }
    }, 1500);

    // console.log('mixpanel before load starts', window?.location.pathname);
    const Mixpanel = mixPanel(
      window?.location.pathname,
      process?.env?.NEXT_PUBLIC_BUILDTYPE
    );

    if (Object.keys(Mixpanel)?.length > 0) {
      // console.log('mixpanel load starts', Mixpanel, window?.location.pathname);

      const mixPanelPageViewObj = {
        page_name: window?.document.title,
      };

      // Track lb page views
      if (window?.location.pathname.startsWith('/linga-bhairavi')) {
        Mixpanel.track('lb_pageview', mixPanelPageViewObj);
      }

      // Track IEO page views
      if (window?.location.pathname.indexOf('/inner-engineering') > -1) {
        Mixpanel.track('ie_pageview', mixPanelPageViewObj);
      }
    }
  }, [pageProps]);

  return (
    <AuthContextProvider>
      <ChakraProvider resetCSS theme={ishaTheme}>
        {(process.env.NEXT_PUBLIC_BUILDTYPE === 'LINGABHAIRAVI' ||
          pageProps.menuType === 'lb') && (
          <LbLayout>
            <Component {...pageProps} />
          </LbLayout>
        )}
        {(process.env.NEXT_PUBLIC_BUILDTYPE === 'MSR' ||
          pageProps.menuType === 'msrmainmenu') && (
          <MsrLayout>
            <Component {...pageProps} />
          </MsrLayout>
        )}
        {pageProps.menuType !== 'msrmainmenu' &&
          pageProps.menuType !== 'lb' &&
          process.env.NEXT_PUBLIC_BUILDTYPE !== 'MSR' &&
          process.env.NEXT_PUBLIC_BUILDTYPE !== 'LINGABHAIRAVI' && (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
      </ChakraProvider>
      <BuildTimeComment />
      {/* <OptinMonsterEmbed /> */}
    </AuthContextProvider>
  );
};

export default ISOApp;
